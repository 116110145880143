import React, { useState, useEffect } from 'react'
import "./mint.scss"
import ProgressBar from 'react-bootstrap/ProgressBar';
import Header from '../landing/Header/Header';
import Footer from '../landing/Footer/Footer';
import Web3 from 'web3';
import MintTokens from '../../hooks/dataSenders/mint';
import { useWeb3React } from '@web3-react/core';
import { toast } from 'react-toastify';
import Environment from '../../utils/Environment';
import useAuth from '../../hooks/useAuth';
import GetTotalSupply from '../../hooks/dataSenders/getSupply';
import Modal from 'react-bootstrap/Modal';
import Loader from '../../hooks/loader';

const Mint = () => {

  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let { account } = useWeb3React();
  const { login, logout } = useAuth();

  const { mintFun } = MintTokens();
  const [totalSupply, setTotalSupply] = useState(0);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [isCopied, setIsCopied] = useState(false);
  const { getTotalSupplyHook } = GetTotalSupply();

  const connectWallet = async (e) => {
    setLoader(true);
    if (account) {
      const connectorId = window.localStorage.getItem("connectorId")
      await logout(connectorId);
      localStorage.removeItem("connectorId");
      localStorage.removeItem("flag");
    } else {
      await login("injected", e);
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
      localStorage.setItem("chain", e);
      setLoader(false);
    }
    setLoader(false);
  };

  const getTotalSupplyFun = async () => {
    console.log('wddfef4 wddfef4');
    try {
      const res = await getTotalSupplyHook();
      console.log(res, 'wddfef4 resssssss 000000000');
      // setSupply(res);
      setTotalSupply(res);
    } catch (error) {
      console.log("🚀 ~ getTotalSupplyFun ~ error:", error)
      // throw error;
    }
  };

  const MintToken = async () => {
    console.log('starttt');
    setLoader(true);
    if (!account) {
      toast.info("Connect Wallet first")
    }
    try {
      const tokensAmount = 0.5;
      console.log("🚀 ~ MintToken ~ tokensAmount:", tokensAmount)
      const res = await mintFun(tokensAmount);
      console.log("🚀 ~ MintToken ~ res:", res);
      toast?.success("NFT mint successfully")
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error('Error in MintToken:', error?.message);
      if (error?.message?.includes("insufficient funds for gas")) {
        toast.error("Insufficient funds for gas. Please check your balance.");
      } else {
        toast.error("An error occurred while minting tokens.");
      }
    }
  };

  useEffect(() => {
    const percentage = (totalSupply / 300) * 100;
    setProgressPercentage(percentage);
  }, [totalSupply]);

  const handleCopyClick = () => {
    const textToCopy = "0x40497269B6E71920Ca705793Df5e3529f4f313d2";
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  useEffect(() => {
    getTotalSupplyFun();
  }, [account])

  return (
    <>
      {loader && <Loader />}
      <div className="wrapper-mint">
        <Header />
        <section className="mainmint">
          <div className="custom-container">
            <div className="parent1">
              <div className='leftdivmainimg'>
                <img
                  className="leftimgg img-fluid"
                  src="\assets\importantimages\leftdiv.png"
                  alt="img"
                />
              </div>
              <div className="parent3">
                {/* <h6 className="para1">50 / 300 king sora minted</h6> */}
                {/* <ProgressBar className="barpro" now={40} /> */}

                <h6 className='para1'><span className="redtext">{`${totalSupply}`} </span> / 300 <span className="redtext">Trump Pepe</span> minted</h6>
                <ProgressBar now={progressPercentage} />
                <div className="parent2">
                  <div className="parsmain"> <img className="para11" src="\assets\importantimages\minus.png" /></div>

                  <p className="para2">1</p>
                  <div className="parsmain"><img className="para11" src="\assets\importantimages\add.png" /></div>

                </div>
                <p className="para3">1 Trump Pepe = 0.5 BNB</p>
                {/* <a className="para4" onClick={() => MintToken()}>Mint</a> */}
                {account ? (
                  <span>
                    <a className="para4" onClick={() => MintToken()}>Mint</a>
                  </span>
                ) : (
                  <a
                    className="para4"
                    onClick={() => connectWallet("56")}
                  >
                    Connect Wallet
                  </a>
                )}
                <p className="para5">Or send 0.5 BNB to this address to receive your NFT</p>
                <p className="para6">
                  {"0x40497269B6E71920Ca705793Df5e3529f4f313d2"}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleCopyClick()}
                  >
                    <path
                      d="M10.6667 8.60004V11.4C10.6667 13.7334 9.73337 14.6667 7.40004 14.6667H4.60004C2.26671 14.6667 1.33337 13.7334 1.33337 11.4V8.60004C1.33337 6.26671 2.26671 5.33337 4.60004 5.33337H7.40004C9.73337 5.33337 10.6667 6.26671 10.6667 8.60004Z"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.6667 4.60004V7.40004C14.6667 9.73337 13.7334 10.6667 11.4 10.6667H10.6667V8.60004C10.6667 6.26671 9.73337 5.33337 7.40004 5.33337H5.33337V4.60004C5.33337 2.26671 6.26671 1.33337 8.60004 1.33337H11.4C13.7334 1.33337 14.6667 2.26671 14.6667 4.60004Z"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </p>
                {isCopied && <div className="copied-message w-100 text-center">Copied!</div>}

              </div>
            </div>
          </div>
          <Modal
            className="networkmodallss"
            show={show}
            onHide={handleClose}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title className="wallettext"></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="mainpara1">
                <img className="imgmodaalll" src="\assets\importantimages\modalimg.png" />
                <p className="para8">
                  Congratulations your nft has been minted </p>
              </div>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
          </Modal>
        </section>
      </div>
    </>
  );
};

export default Mint;
