import React, { useState, useEffect } from "react";
import Web3 from "web3";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import useAuth from "../../../hooks/useAuth";
import "./header.scss";
import { HashLink } from 'react-router-hash-link';
import { useLocation } from "react-router-dom";

const Header = () => {

  const { hash } = useLocation();
  const isActive = (iHash) => hash === iHash;

  const [web3, setWeb3] = useState(null);

  let { account } = useWeb3React();
  console.log("🚀 ~ Header ~ account:", account)

  const { login, logout } = useAuth();

  const connectWallet = async (e) => {
    if (account) {
      const connectorId = window.localStorage.getItem("connectorId")
      await logout(!connectorId);
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const accounts = await web3.eth.getAccounts();
      localStorage.removeItem("connectorId");
      localStorage.removeItem("flag");
    } else {
      await login("injected", e);
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
      localStorage.setItem("chain", e);
      // setLoader(false);
    }
    // setLoader(false);
  };


  const disconnectWallet = async () => {
    const connectorId = window.localStorage.getItem("connectorId")
    logout(connectorId)
    localStorage.removeItem('connectorId')
    localStorage.removeItem('flag')
    localStorage.removeItem('chain')
  }

  return (
    <>
      <section className="main-navbar">
        <div class="custom-container">
          <nav class="navbar navbar-expand-xl">
            <a class="navbar-brand" href="/">
              <img src="\logo.svg" alt="mainlogo" className="mainlogo" />
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect x="2" y="14.4209" width="7.57895" height="7.57895" fill="white" />
                <rect x="2" y="2" width="7.57895" height="7.57895" fill="white" />
                <rect x="14.421" y="14.4209" width="7.57895" height="7.57895" fill="white" />
                <rect x="14.421" y="2" width="7.57895" height="7.57895" fill="white" />
              </svg>
            </button>
            <div class="collapse navbar-collapse mobile-none" id="navbarSupportedContent">
              <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <HashLink to="#Home" class="nav-link" style={
                    isActive("#Home")
                      ? {
                        fontWeight: "700",
                      }
                      : {}
                  }> <a>Home</a></HashLink>
                </li>
                <li class="nav-item">
                  <HashLink to="#About" class="nav-link" style={
                    isActive("#About")
                      ? {
                        fontWeight: "700"
                      }
                      : {}
                  }><a >About</a></HashLink>
                </li>
                <li class="nav-item">
                  <HashLink to="#Partners" class="nav-link" style={
                    isActive("#Partners")
                      ? {
                        fontWeight: "700"
                      }
                      : {}
                  }> <a>Partners</a></HashLink>
                </li>
                <li class="nav-item">
                  <HashLink class="nav-link" to="#Tokenomics" style={
                    isActive("#Tokenomics")
                      ? {
                        fontWeight: "700"
                      }
                      : {}
                  }><a>Tokenomics</a></HashLink>
                </li>
                <li class="nav-item">
                  <HashLink class="nav-link" to="#Roadmap" style={
                    isActive("#Roadmap")
                      ? {
                        fontWeight: "700"
                      }
                      : {}
                  }> <a >Roadmap</a></HashLink>
                </li>

              </ul>
              <div className="socialicons">
                <a href="" target="_blank" className="innerscoialicoin">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M18.1216 2.44128H21.3647L14.2794 10.5393L22.6147 21.5589H16.0883L10.9765 14.8756L5.12747 21.5589H1.88237L9.4608 12.8972L1.46472 2.44128H8.15688L12.7775 8.55011L18.1216 2.44128ZM16.9834 19.6178H18.7804L7.18041 4.2805H5.25198L16.9834 19.6178Z" fill="white" />
                  </svg>
                </a>
                <a href="" target="_blank" className="innerscoialicoin">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clip-path="url(#clip0_1_46)">
                      <path d="M17.3823 8.5846C18.1039 7.88521 18.8203 6.25331 15.8135 8.23491C13.0047 10.1084 10.1769 11.9549 7.33046 13.7743C6.89701 13.9642 6.42842 14.0673 5.95283 14.0773C5.47724 14.0873 5.00446 14.0041 4.56291 13.8325C2.75884 13.308 0.652788 12.6086 0.652788 12.6086C0.652788 12.6086 -0.785239 11.7331 1.6764 10.8006C1.6764 10.8006 12.0838 6.66128 15.6932 5.20296C17.0764 4.62013 21.7682 2.7551 21.7682 2.7551C21.7682 2.7551 23.9344 1.93788 23.754 3.92075C23.6939 4.7367 23.2128 7.59507 22.7317 10.684C22.0088 15.0565 21.227 19.8382 21.227 19.8382C21.227 19.8382 21.1068 21.1787 20.0844 21.4118C18.9901 21.3938 17.9327 21.0249 17.0777 20.3627C16.8371 20.1879 12.5662 17.5639 11.0013 16.2817C10.8422 16.1772 10.7128 16.0353 10.6254 15.8694C10.538 15.7034 10.4953 15.5187 10.5013 15.3325C10.5073 15.1462 10.5618 14.9645 10.6597 14.8041C10.7577 14.6438 10.8959 14.51 11.0615 14.4154C13.2077 12.5122 15.3151 10.5683 17.3823 8.5846Z" fill="white" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_46">
                        <rect width="23.5294" height="23.5294" fill="white" transform="translate(0.235291 0.235352)" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </div>
              <div className="twice-btns-formint d-none">
                <a href="/whitelist" className="btn-whitelist">Whitelist</a>
                {/* <button className="btn-connect ifdisconnect">connect wallet</button> */}

                {account ? (
                  <span >
                    {/* Connected: {accounts[0]} */}
                    <button className="btn-connect ifdisconnect" onClick={() => disconnectWallet()}>
                      Disconnect Wallet
                    </button>
                  </span>
                ) : (
                  <button
                    className="btn-connect ifdisconnect"
                    onClick={() => connectWallet("56")}
                  >
                    Connect Wallet
                  </button>
                )}

              </div>
            </div>
          </nav>
        </div>
      </section>
    </>
  );
};
export default Header;