let initState = {
  GetUserAll: [],
  loader:false
};

export const UserReducer = (state = initState, action) => {
  const { type, payload } = action; //object destructring
  // console.log("data in reducer",payload)
  switch (type) {
    case "GETUSER":
      return {
        ...state,
        GetUserAll: payload,
      };

      case "loader":
        return {
          ...state,
          loader: payload,
        };
      
    case "BALANCE":
      return {
        ...state,
        balance: payload,
      };
    case "USER_REWARD":
      
      return {
        ...state,
        reward: payload,
      };

    default:
      return state;
  }
};