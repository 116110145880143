import { useCallback, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getAllContracts } from "../../utils/contractHelpers";
import Web3 from "web3";

const MintTokens = () => {

    const { account } = useWeb3React();
    console.log("🚀 ~ MintTokens ~ account:", account)

    // const web3 = new Web3(window.ethereum);
    const web3 = useWeb3();
    const stakingAddress = environment.CollectionContracts;
    const contract = getAllContracts(stakingAddress, web3);
    // console.log(contract?.methods?.mint, contract, "moizzzzzzz");


    // const getTotalSupply = async () => {
    //     try {
    //         const totalSupply = await contract.methods.totalSupply().call();
    //         console.log(totalSupply, "wddfef4");
    //         // return totalSupply;
    //     } catch (error) {
    //         // throw error;
    //     }
    // };
    // useEffect(() => {
    //     getTotalSupply()
    // }, [])

    const mintFun = useCallback(
        async (tokensAmount) => {
            console.log("accept a bid", tokensAmount)

            let gasPrice = await web3?.eth?.getGasPrice();
            gasPrice = parseInt(gasPrice) + 2000000000

            let towayValue = web3?.utils?.toWei(tokensAmount?.toString(), "ether");
            console.log("🚀 ~ file: accept.js:25 ~ towayValue:", towayValue)

            try {
                const response = await contract?.methods?.buyNFTs()?.send({
                    from: account,
                    value: towayValue
                })
                    .catch((error) => {
                        console.log("error1")
                        throw error;
                    });
                return response;
            } catch (e) {
                console.log("error2", e)
                throw e;
            }

        },
        [contract, account]
    );


    return { mintFun: mintFun }
}

export default MintTokens;