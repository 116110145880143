import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
// import Landing from './components/landing/Landing';
import Mint from './components/Mint/Mint';
import Whitelist from './components/Whitelist/Whitelist';
import useEagerConnect from './hooks/useEagerConnect';

function App() {
  useEagerConnect();

  return (
    <>
      <Router>
        {/* <Route exact path='/' component={Landing} /> */}
        <Route exact path='/' component={Mint} />
        <Route exact path='/whitelist' component={Whitelist} />
      </Router>
    </>
  );
}

export default App;
