import React from 'react'
import './whitelist.scss'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Header from '../landing/Header/Header';
import GetWhiteList from '../../hooks/dataSenders/getWhitelist';
import { useWeb3React } from '@web3-react/core';
import useAuth from '../../hooks/useAuth';
import Loader from '../../hooks/loader';


const Whitelist = () => {

  const [loader, setLoader] = useState(false);

  const { login, logout } = useAuth();
  let { account } = useWeb3React();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);


  const [isWhitelisted, setIsWhitelisted] = useState(null);
  const [address, setAddress] = useState('');

  const { getWhiteListHook } = GetWhiteList();

  const getWhiteListNft = async () => {
    try {
      // setLoader(true);
      const res = await getWhiteListHook(address);
      // setLoader(false);
      console.log(res, ' res in getWhiteListHook');
      setIsWhitelisted(res);
      if (res === '1') {
        console.log(res, ' res in getWhiteListHook 11111111111111111111111');
        handleShow();
      } else {
        handleShow2();
      }
      setTimeout(() => {
        setIsWhitelisted(null);
      }, 2000);
    } catch (error) {
      console.log("🚀 ~ getWhiteListNft ~ error:", error)
      // throw error;
    }
  };



  const connectWallet = async (e) => {
    if (account) {
      const connectorId = window.localStorage.getItem("connectorId")
      await logout(connectorId);
      localStorage.removeItem("connectorId");
      localStorage.removeItem("flag");
      // setAccounts(accounts);
    } else {
      await login("injected", e);
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
      localStorage.setItem("chain", e);
      // setLoader(false);
    }
    // setLoader(false);
  };

  const disconnectWallet = async () => {
    const connectorId = window.localStorage.getItem("connectorId")
    logout(connectorId)
    localStorage.removeItem('connectorId')
    localStorage.removeItem('flag')
    localStorage.removeItem('chain')
  };

  return (
    <>
      {loader && <Loader />}

      <div className="wrapper-mint">
        <Header />
        <section className="mainmint2">
          <div className="custom-container">
            <div className="parent1">
              <div className='leftdivmainimg'>
                <img
                  className="leftimgg img-fluid"
                  src="\assets\importantimages\leftdiv.png"
                  alt="img"
                />
              </div>
              <div className="parent4">
                <h4 className="whitelisthead">see if you are in the whitelist</h4>
                {
                  account ?
                    <button className='butclass ifdisconnect' onClick={disconnectWallet}>Disonnect wallet</button>
                    :
                    <button className='butclass ifdisconnect' onClick={() => connectWallet("56")}>Connect wallet</button>
                }
                <p className='para10'>OR</p>
                <InputGroup className=" inputfoam22">
                  <input
                    placeholder="Write your wallet address..."
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className='form-control'
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  <InputGroup.Text id="basic-addon2" className='inputfoam'
                    onClick={() => getWhiteListNft()}
                  >Check</InputGroup.Text>
                </InputGroup>

                {isWhitelisted !== null && (
                  <div>
                    {isWhitelisted === '1' ? (
                      <h6 className="extra-text">COngratulations! you are in the whitelist!</h6>

                    ) : (
                      <h6 className="extra-text">You are not in the whitelist!</h6>

                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <Modal
            className="networkmodallss"
            show={show}
            onHide={handleClose}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title className="wallettext"></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="mainpara1">
                <img className="imgmodaalll" src="\assets\importantimages\modalimg.png" />
                <p className="para8">
                  Congratulations you are in the whitelist </p>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            className="networkmodallss"
            show={show2}
            onHide={handleClose2}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title className="wallettext"></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="mainpara1">
                <img className="imgmodaalll" src="\assets\importantimages\modalimg.png" />
                <p className="para8">
                  Unfortunately you are not in the whitelist </p>
              </div>
            </Modal.Body>
          </Modal>
        </section>
      </div>
    </>
  )
}

export default Whitelist