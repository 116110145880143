import { useCallback, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getAllContracts } from "../../utils/contractHelpers";
import Web3 from "web3";

const GetTotalSupply = () => {
    const getTotalSupplyHook = useCallback(
        async () => {
            const web3 = new Web3(window.ethereum);
            const stakingAddress = environment.CollectionContracts;
            console.log("!!!!", web3, window.ethereum, stakingAddress)
            const contract = getAllContracts(stakingAddress, web3);
            if (contract) {
                console.log("start suplllllyyyyy hoook",)

                try {
                    const totalSupply = await contract.methods.totalSupply().call();
                    console.log(totalSupply, "wddfef4");
                    return totalSupply;
                } catch (error) {
                    console.log(error, 'wddfef4 error ');
                    throw error;
                }
            }
        },
        []
    );

    return { getTotalSupplyHook: getTotalSupplyHook }
}

export default GetTotalSupply;