import { useCallback, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getAllContracts } from "../../utils/contractHelpers";
import Web3 from "web3";

const GetWhiteList = () => {

    const { account } = useWeb3React();
    console.log("🚀 ~ MintTokens ~ account:", account)
    const web3 = new Web3(window.ethereum);
    const stakingAddress = environment.CollectionContracts;
    const contract = getAllContracts(stakingAddress, web3);

    const getWhiteListHook = useCallback(
        async (address) => {
            // console.log("start suplllllyyyyy hoook",)

            try {
                const whitelist = await contract?.methods?.whiteList(address)?.call();
                console.log("🚀 ~ whitelist:", whitelist)
                // console.log(whitelist, "sss");
                return whitelist;
            } catch (error) {
                console.log("🚀 ~ error:", error);
                throw error;
            }

        },
        [contract, account]
    );
    return { getWhiteListHook: getWhiteListHook }
}

export default GetWhiteList;