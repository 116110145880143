
import contractAbi from '../utils/ContractAbi.json';
import web3NoAccount from '../hooks/web3';

// import contractAbi 

const getContract = (abi, address, web3) => {
    const _web3 = web3 ?? web3NoAccount;
    // console.log('_web3',_web3);
    return new _web3.eth.Contract(abi, address)
}


export const getAllContracts = (address, web3) => {
    return getContract(contractAbi, address, web3)
}